
import classNames from "classnames";
import BrandingIconCard from "../BrandingIconCard/BrandingIconCard";
import IconCollection from "../IconCollection/IconCollection";
import css from "./MerchantBanner.module.css"
import { FormattedMessage } from "react-intl";

const MerchantBanner = (props) => {
    const {
        businessTitle,
        categoryLevel1Object,
        categoryLevel2Object,
        intl,
    } = props
    const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";
    return (
        <div className={classNames(css.bannerContainer, arabicLanguage && css.arabicDirection)}>
            <h2 className={css.vendorTitle}>{businessTitle}</h2>
           <div className={css.starContent}>
           <p className={css.subTitle}>
                {categoryLevel1Object?.id && intl.formatMessage({ id: `category.${categoryLevel1Object?.id}` })}
                {categoryLevel2Object?.id &&
                    ` / ${intl.formatMessage({ id: `category.${categoryLevel2Object?.id}` })}`
                }
            </p>
          {categoryLevel1Object?.id &&
            <div className={css.rating}>
                <div>
                    <BrandingIconCard type="star_2" />
                    <BrandingIconCard type="star_2" />
                    <BrandingIconCard type="star_2" />
                    <BrandingIconCard type="star_2" />
                    <BrandingIconCard type="star_2" />
                </div>
                <span className={css.reviewNumber}>0</span>
                <span>
                    {intl.formatMessage({ id: 'ViewBusiness.ratings' }, { ratings: 0 })}
                </span>
            </div>}
           </div>

            <div className={css.poweredByBanner}>
                <div className={css.bannerContent}>
                <p className={css.poweredText}><FormattedMessage id="ViewBusiness.poweredBy"/></p>
                <IconCollection name="poweredByIcon"/>
                </div>
            <span className={css.backIcon}><IconCollection name="poweredBy"/></span>
            </div>
        </div>
    )
}

export default MerchantBanner;