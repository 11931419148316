import React from 'react';
import { FormattedMessage } from 'react-intl';
import Loader from '../Loader/Loader';
import css from "./ReviewSummary.module.css";

// Helper function to format ratings data
const formatRating = (detailedRating) => {
  if (!detailedRating || typeof detailedRating !== 'object') {
    console.error('Invalid input: detailedRating must be an object');
    return [
      { count: 0, percentage: 0 },
      { count: 0, percentage: 0 },
      { count: 0, percentage: 0 },
      { count: 0, percentage: 0 },
      { count: 0, percentage: 0 }
    ];
  }

  // Extract ratings and ensure valid numbers
  const ratings = [
    Number(detailedRating.fivestar) || 0,
    Number(detailedRating.fourstar) || 0,
    Number(detailedRating.threestar) || 0,
    Number(detailedRating.twostar) || 0,
    Number(detailedRating.onestar) || 0,
  ];

  // Find the maximum value among all star ratings
  const maxStarValue = Math.max(...ratings);

  // Create array of objects with count and percentage
  const result = ratings.map(starCount => {
    const percentage = maxStarValue === 0
      ? 0
      : Number(((starCount / maxStarValue) * 100).toFixed(1));
    return {
      count: starCount,
      percentage: percentage
    };
  });
  return result;
};

const ReviewSummary = (props) => {
  const { listingPublicData = {}, ratings = {}, ratingsInProgress, ratingsError } = props;
  const { totalReviews = 0, averageRating = 0 } = listingPublicData || {};

  // Format the ratings using the helper function
  const formattedRatings = formatRating(ratings);

  if (ratingsInProgress) {
    return <Loader />
  }

  if (ratingsError) {
    return <FormattedMessage id="ReviewSummary.error" />
  }

  return (
    <div className={css.reviewSummaryContainer}>
      <h4 className={css.title}><FormattedMessage id="ReviewSummary.title" /></h4>
      <div className={css.summarySection}>
        <div className={css.leftSection}>
          <div className={css.container}>
            {formattedRatings.map(({ percentage, count }, index) => (
              <div key={index} className={css.row}>
                <span className={css.label}>{5 - index} ⭐</span>
                <div className={css.progressBar}>
                  <div
                    className={css.progress}
                    style={{ width: `${percentage}%` }}
                  ></div>
                </div>
                <span className={css.count}>{count}</span>
              </div>
            ))}
          </div>
        </div>

        <div className={css.rightSection}>
          <div className={css.startText}>
            {averageRating ? parseFloat(averageRating)?.toFixed(1) : "0"}
            <span>⭐</span>
          </div>
          <p className={css.totalReviews}>
            {totalReviews} <FormattedMessage id="ReviewSummary.reviews" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReviewSummary;
