import React from 'react';
import { Button, NamedLink, ResponsiveImage } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { SERVICE_LISTING_TYPE } from '../../util/types';
import { createSlug } from '../../util/urlHelpers';
import css from './DealItemsMaybe.module.css';

const DealItemsMaybe = (props) => {
  const {
    dealItem,
    setIsBookingModalOpen,
    isBooked,
    onFetchDealItemMonthlyTimeSlots
  } = props;

  const { images = [], attributes } = dealItem || {}
  const { title, publicData } = attributes || {}
  const { listingType } = publicData || {};

  const isService = listingType === SERVICE_LISTING_TYPE;
  const firstImage = images && images?.length > 0 ? images[0] : null;

  const dealItemId = dealItem?.id?.uuid || "";
  const slug = createSlug(title);

  return (
    <div>
      <div className={css.dealItem}>
        <NamedLink
          name={"ListingPage"}
          params={{ slug, id: dealItemId }}
        >
          <ResponsiveImage
            image={firstImage}
            variants={['listing-card', 'listing-card-2x']}
            alt={'listing image'}
          />
          <h3>{title}</h3>
        </NamedLink>
        {isService ?
          isBooked ?
            <Button type="button" disabled={true}>
              <FormattedMessage id="DealItemsMaybe.booked" />
            </Button>
            :
            <Button type="button"
              onClick={(e) => {
                e.stopPropagation();
                setIsBookingModalOpen()
                onFetchDealItemMonthlyTimeSlots(dealItem)
              }}>
              <FormattedMessage id="DealItemsMaybe.bookService" />
            </Button>
          : null}
      </div>

    </div>
  );
}

export default DealItemsMaybe;
