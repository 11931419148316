import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BrandingIconCard from '../BrandingIconCard/BrandingIconCard';
import css from "./ReviewCards.module.css";
import merchatLogo from "../../assets/merchatLogo.png";
import customerLogo from "../../assets/customerLogo.png";
import Modal from "../../components/Modal/Modal";
import Loader from '../Loader/Loader';


const ReviewCards = (props) => {
  const {
    reviews = [],
    reviewType = "listing",
    onManageDisableScrolling,
    reviewsInProgress,
    reviewsError
  } = props;

  const isMerchantReview = reviewType === "merchant";
  const [modalImage, setModalImage] = useState(null);

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  if (reviewsInProgress) {
    return <Loader />
  }

  if (reviewsError) {
    return <FormattedMessage id="ReviewCards.error" />
  }

  return (
    <div>
      {/* <h4><FormattedMessage id={isMerchantReview ? "ReviewCards.titleMerchant" : "ReviewCards.titleListing"} /></h4> */}
      {(reviews?.reviews || reviews)?.length > 0 ? (
        <div>
          {(reviews?.reviews || reviews).map(r => {
            const {
              id,
              listing_id,
              staf_id,
              reviewer_id,
              reviewer_type,
              other_user_id,
              content,
              rating,
              images,
              created_at,
              reviewerName,
              listingTitle,
            } = r;

            const formattedDate = moment(created_at).format('MMM DD, YYYY');

            return (
              <div key={listing_id} className={css.reviewCard}>
                <div className={css.cardHeader}>
                  <div className={css.cardContents}>
                    <div className={css.profileImages}>
                      {isMerchantReview ? <img className={css.profileImage} src={merchatLogo} alt="" /> :
                        <img className={css.profileImage} src={customerLogo} alt="" />}
                    </div>
                    <h4 className={css.reviewerName}>{reviewerName}</h4>
                  </div>
                  <div className={css.reviewHeader}>
                    <div className={css.rating}>
                      <BrandingIconCard type={rating >= 1 ? "star" : "star_2"} />
                      <BrandingIconCard type={rating >= 2 ? "star" : "star_2"} />
                      <BrandingIconCard type={rating >= 3 ? "star" : "star_2"} />
                      <BrandingIconCard type={rating >= 4 ? "star" : "star_2"} />
                      <BrandingIconCard type={rating >= 5 ? "star" : "star_2"} />
                    </div>
                    {/* <p className={css.reviewTitle}><b>{title}</b></p> */}
                  </div>
                </div>
                <div>
                  {listingTitle ? <div className={css.listingTitle}>{listingTitle}</div> : null}
                  <div className={css.reviewDescription}>{content}</div>
                </div>
                <div className={css.reviewImages} style={{ overflowX: images.length > 3 ? 'auto' : 'visible' }}>
                  {images?.length > 0 ? (
                    <>
                      {images.map((image, index) => (
                        <div key={index} className={css.imageWrapper}>
                          <img
                            src={image}
                            className={css.reviewImage}
                            onClick={() => openModal(image)}
                          />
                        </div>
                      ))}
                    </>
                  ) : null}
                </div>
                <p className={css.reviewDate}>{formattedDate}</p>
              </div>
            );
          })}
        </div>
      ) : <div>
        <FormattedMessage id="ReviewCards.noReviews" />
      </div>}
      {modalImage && (
        <Modal
          onClose={closeModal}
          isOpen={!!modalImage}
          onRequestClose={closeModal}
          className={css.modal}
          overlayClassName={css.modalOverlay}
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <img src={modalImage} alt='Review Image' className={css.modalImage} />
        </Modal>
      )}
    </div>
  );
}

export default ReviewCards;
