import React from 'react';
import Slider from "react-slick";
import css from './ListingGallery.module.css';
import { ResponsiveImage } from '../../../components';
import classNames from 'classnames';
import merchatLogo from '../../../assets/merchatLogo.png'
import customerLogo from '../../../assets/customerLogo.png'

function ListingGallery(props) {
    const { listing, variantPrefix, userType } = props;
    const images = listing?.images || [];

    let settings = {
        dots: true,
        speed: 1000,
        lazyLoad: true,
        infinite: false,
        centerMode: false,
        slidesToScroll: 1,
        slidesToShow: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };

    return (
        <div className={classNames(css.listingGrid, images.length === 3 && css.threeImages)}>
            {images.length === 0 ?
             <div
             className={css.galleryImage}
         >
           {userType == 'provider' ?  <ResponsiveImage
                 rootClassName={css.avatarImage}
                 alt={'Gallery'}
                 localImageSrc={merchatLogo}
                 variants={"custom-image"}
             /> :
             <ResponsiveImage
                 rootClassName={css.avatarImage}
                 alt={'Gallery'}
                 localImageSrc={customerLogo}
                 variants={"custom-image"}
             />}
         </div>
            :
            <Slider {...settings}>
                {images.map((item, i) => {
                    const { url = "" } = item?.attributes?.variants?.[variantPrefix];
                    return (
                        <div
                            className={css.galleryImage}
                            key={i}
                        >
                            <ResponsiveImage
                                rootClassName={css.avatarImage}
                                alt={'Gallery'}
                                image={item}
                                variants={['listing-card-2x']}
                            />
                        </div>
                    )
                })}
            </Slider>}
        </div>
    )
}

export default ListingGallery