import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';

// Contexts
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
// Utils
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import {
  convertCategoriesList,
  ensureListing,
  ensureOwnListing,
  ensureUser,
  transformCategories,
  userDisplayNameAsString
} from '../../util/data';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import { DEAL_LISTING_TYPE, LISTING_STATE_CLOSED, LISTING_STATE_DRAFT, LISTING_STATE_PENDING_APPROVAL, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE, STAFF_LISTING_TYPE, propTypes } from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
  parse,
} from '../../util/urlHelpers';

// Global ducks (for Redux actions and thunks)
import { getListingsById, getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';

// Shared components
import {
  BrandingIconCard,
  H4,
  Heading,
  LayoutSingleColumn,
  Modal,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  Page,
} from '../../components';

// Related components and modules
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import {
  fetchDealItemMonthlyTimeSlots,
  fetchStaffListings,
  fetchTimeSlots,
  fetchTransactionLineItems,
  sendInquiry,
  setInitialValues,
  toggleCart,
} from './ListingPage.duck';

import ActionBarMaybe from './ActionBarMaybe';
import CustomListingFields from './CustomListingFields';
import {
  ErrorPage,
  LoadingPage,
  handleAddToCart,
  handleContactUser,
  handleSubmit,
  handleSubmitInquiry,
  listingImages,
  priceData,
} from './ListingPage.shared';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionTextMaybe from './SectionTextMaybe';

import { displayPrice } from '../../util/configHelpers.js';

import moment from 'moment';
import { classNames } from 'primereact/utils';
import Slider from 'react-slick';
import MerchantBanner from '../../components/MerchantBanner/MerchantBanner.js';
import ReviewCards from '../../components/ReviewCards/ReviewCards.js';
import ReviewSummary from '../../components/ReviewSummary/ReviewSummary.js';
import { createResourceLocatorString } from '../../util/routes.js';
import CustomerFooter from '../LandingPage/CustomerHomePage/CustomerFooter/CustomerFooter.js';
import DealCard from '../WishlistPage/DealCard.js';
import ProductCard from '../WishlistPage/ProductCard.js';
import ServiceCard from '../WishlistPage/ServiceCard.js';
import { toggleFavorite } from '../WishlistPage/WishlistPage.duck.js';
import DealItemsMaybe from './DealItemsMaybe.js';
import ListingGallery from './ListingGallery/ListingGallery';
import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

// Show Listing Price
const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <span className={css.price}>{formatMoney(intl, price)}</span>
      {/* <span className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </span> */}
    </div>
  );
};

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  // const [activeTab, setActiveTab] = useState('About');

  // const handleTabClick = tabName => {
  //   setActiveTab(tabName);
  // };

  const [categoryVisible, setCategoryVisible] = useState(["category", "price", "tags", "otherInfo", "additionalInfo"]);

  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [dealServicesData, setDealServicesData] = useState([]);

  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsInProgress,
    fetchReviewsError,
    ratings,
    fetchRatingsInProgress,
    fetchRatingsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    itemMonthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    onFetchStaffListings,
    staffListings,
    fetchStaffListingsError,
    fetchStaffListingsInProgress,
    onToggleCart,
    businessProfileListing,
    dealItemsListings,
    relatedItemsListings = [],
    onFetchDealItemMonthlyTimeSlots,
    onToggleFavorite,
    categories = [],
  } = props;

  const queryParams = parse(location.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const isBookingLink = queryParams?.bookingLink === true;

  const { currency: marketplaceCurrency } = config;

  const organizedCategories = transformCategories(categories) || [];
  const updatedDealCategories = convertCategoriesList(categories || []);

  const isDealItemClosed = dealItemsListings.reduce((acc, item) => {
    if (item?.attributes?.state !== "published") {
      acc = true;
    }
    return acc;
  }, false);

  const toggleVisibility = name => {
    setCategoryVisible(prevState => {
      // Check if the category is already in the array
      if (prevState.includes(name)) {
        // If it's open (in the array), remove it
        return prevState.filter(item => item !== name);
      } else {
        // If it's closed (not in the array), add it
        return [...prevState, name];
      }
    });
  };
  const goBack = () => {
    history.goBack();
  };

  const userType = currentUser?.attributes?.profile?.publicData?.userType;
  const listingConfig = config.listing;
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage staticContext={props.staticContext} />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = new Money(0, marketplaceCurrency),
    title = '',
    publicData = {},
    metadata = {},
    state: listingState
  } = currentListing.attributes;

  const isPendingApproval = listingState === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = listingState === LISTING_STATE_CLOSED;
  const isDraft = listingState === LISTING_STATE_DRAFT;

  const { deal_start_date, deal_end_date } = publicData;

  const currentDate = moment();
  const dealStartDate = moment.unix(deal_start_date);
  const dealEndDate = moment.unix(deal_end_date);

  // Check if the deal is valid (start date <= current date <= end date)
  const isDealValid = currentDate.isBetween(dealStartDate, dealEndDate, null, '[]'); // '[]' includes start and end dates
  const isDealStarted = currentDate.isSameOrAfter(dealStartDate);

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const {
    listingType,
    transactionProcessAlias,
    unitType,
    categoryLevel1,
    categoryLevel2,
    original_price,
    otherInfo,
  } = publicData;

  let { totalReviews = 0, averageRating = 0 } = publicData

  const categoryLevel1Object = organizedCategories?.find(cat =>
    categoryLevel1 && cat.id == categoryLevel1
  ) || {};

  const categoryLevel2Object = (categoryLevel1Object?.childCategory || []).find(cat =>
    categoryLevel2 && cat?.id == categoryLevel2
  ) || {};

  const dealOriginalPrice = original_price ? new Money(original_price * 100, marketplaceCurrency) : null;
  const listingPrice = price || null;

  const isDeal = listingType === DEAL_LISTING_TYPE;
  const isStaff = listingType === STAFF_LISTING_TYPE;
  const dealItemPublicData = dealItemsListings?.[0]?.attributes?.publicData || {};

  if (isDeal) {
    if (dealItemPublicData?.totalReviews) {
      totalReviews = dealItemPublicData?.totalReviews;
    }
    if (dealItemPublicData?.averageRating) {
      averageRating = dealItemPublicData?.averageRating;
    }
  }


  if (!(listingType && transactionProcessAlias && unitType)) {
    // Listing should always contain listingType, transactionProcessAlias and unitType)
    return (
      <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} invalidListing />
    );
  }
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? ('booking' ? isPurchase : 'purchase') : 'inquiry';

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  const { publicData: userPublicData } = currentUser?.attributes?.profile || {};
  const { destinationId, businessId } = userPublicData || {};

  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && processType !== 'inquiry' && !(businessId && destinationId);

  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <span className={css.payoutDetailsWarning}>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </span>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const schemaPriceMaybe = price
    ? {
      price: intl.formatNumber(convertMoneyToNumber(price), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      priceCurrency: price.currency,
    }
    : {};
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability = !currentListing.currentStock
    ? null
    : currentStock > 0
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock';

  const availabilityMaybe = schemaAvailability ? { availability: schemaAvailability } : {};

  // Cart functionality – add this within the component but before the return statement
  const onAddToCart = handleAddToCart({
    ...commonParams,
    location,
    currentUser,
    onToggleCart,
    listingId: listingId?.uuid,
    authorId: currentAuthor?.id?.uuid,
    listing: currentListing,
  });

  const cart = currentUser?.attributes?.profile?.privateData?.cart;
  const listingCartCount =
    (cart &&
      cart[currentAuthor?.id.uuid] &&
      cart[currentAuthor?.id.uuid][currentListing?.id?.uuid]?.count) ||
    0;

  const listingNotActive = isPendingApproval || isClosed || isDraft;

  const cartProps = {
    listing: currentListing,
    count: listingCartCount,
    incrementCart: onAddToCart,
    isListingPage: true,
    isOwnListing,
    listingNotActive,
  };
  // cart functionality ends
  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const getOtherLabel = (listingType) => {
    switch (listingType) {
      case PRODUCT_LISTING_TYPE:
        return <FormattedMessage id="ListingPage.otherProducts" />
      case SERVICE_LISTING_TYPE:
        return <FormattedMessage id="ListingPage.otherServices" />
      case DEAL_LISTING_TYPE:
        return <FormattedMessage id="ListingPage.otherDeals" />
    }
  }

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <svg width="30px" height="30px" viewBox="0 0 1.8 1.8" fill="none"><path width="48" height="48" fill="white" fill-opacity="0.01" d="M0 0H1.8V1.8H0V0z" /><path d="m0.713 0.45 0.45 0.45 -0.45 0.45" stroke="#000000" stroke-width="0.15" stroke-linecap="round" stroke-linejoin="round" /></svg>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}
      >
        <svg width="30px" height="30px" viewBox="0 0 1.8 1.8" fill="none" xmlns="http://www.w3.org/2000/svg"><path width="48" height="48" fill="white" fill-opacity="0.01" d="M0 0H1.8V1.8H0V0z" /><path d="M1.162 1.35 0.713 0.9l0.45 -0.45" stroke="#000000" stroke-width="0.15" stroke-linecap="round" stroke-linejoin="round" /></svg>
      </div>
    );
  }

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 2.5,
    lazyLoad: true,
    slidesToScroll: 1,
    centerMode: false,
    speed: 500,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
        },
      },
    ],
  };

  const {
    title: businessTitle,
    publicData: businessPublicData,
  } = businessProfileListing || {};

  const {
    business_category,
    business_subcategory,
    location: businessLocation,
  } = businessPublicData || {};

  const businessCategoryLevel1Object = organizedCategories?.find(cat =>
    business_category && cat?.id == business_category
  ) || {};

  const businessCategoryLevel2Object = (businessCategoryLevel1Object?.childCategory || []).find(cat =>
    business_subcategory && cat?.id == business_subcategory
  ) || {};

  const merchantDetails = {
    name: businessTitle,
    location: businessLocation,
  }

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...schemaPriceMaybe,
          ...availabilityMaybe,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={userType == 'provider' ? <FooterContainer /> : <CustomerFooter />}>
        <div className={classNames(css.listingWrapper, arabicLanguage && css.arabicDirection)}>
          {currentListing.id && noPayoutDetailsSetWithOwnListing ? (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
              isDealItemClosed={isDealItemClosed || (isDeal && !dealItemsListings?.length)}
            />
          ) : null}
          {currentListing.id ? (
            <ActionBarMaybe
              className={css.actionBarForProductLayout}
              isOwnListing={isOwnListing}
              listing={currentListing}
              isDealItemClosed={isDealItemClosed || (isDeal && !dealItemsListings?.length)}
              editParams={{
                id: listingId.uuid,
                slug: listingSlug,
                type: listingPathParamType,
                tab: listingTab,
              }}
            />
          ) : null}
          <div className={css.listingTopHeading}>
            {/* <div className={css.mobileTopContent}>
              <div className={css.backButton} onClick={goBack}>
                <IconCollection name="back_icon" />
              </div>
              <div className={css.rightIcons}>
                <div className={css.circleGray}></div>
                <IconCollection name="love_icon" />
                <IconCollection name="send_icon" />
              </div>
            </div> */}


            {isBookingLink ?
              <MerchantBanner
                businessTitle={businessTitle}
                categoryLevel1Object={businessCategoryLevel1Object}
                categoryLevel2Object={businessCategoryLevel2Object}
                intl={intl}
              />
              : null}

            <div className={css.listingHeading}>{richTitle}</div>

            <div className={css.reviewDetails}>
              <div>
                <BrandingIconCard type={averageRating >= 1 ? "star" : "star_2"} />
                <BrandingIconCard type={averageRating >= 2 ? "star" : "star_2"} />
                <BrandingIconCard type={averageRating >= 3 ? "star" : "star_2"} />
                <BrandingIconCard type={averageRating >= 4 ? "star" : "star_2"} />
                <BrandingIconCard type={averageRating >= 5 ? "star" : "star_2"} />
              </div>
              <span className={css.reviewNumber}>{totalReviews}</span>
            </div>
            {/* <div className={css.subTitleMobile}><FormattedMessage id="ListingPage.hairCut" /></div> */}

          </div>
          {/* <ListingTab handleTabClick={handleTabClick} activeTab={activeTab} /> */}

          <div
            className={classNames(
              css.listingGallery,
              // activeTab === 'About' && css.listingGalleryMobile
            )}
          >
            {/* <div className={css.galleryMobile}><FormattedMessage id="ListingPage.gallery" /></div> */}
            <ListingGallery
              userType={userType}
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
          </div>
          <div className={css.contentWrapperForProductLayout}>
            <div className={css.mainColumnForProductLayout}>
              {description ? (
                <div
                  className={classNames(
                    css.listingSections,
                    // activeTab === 'About' && css.listingSectionsMobile
                  )}
                >
                  <div className={css.headingName}>
                    <FormattedMessage id="ListingPage.itemDescription" />
                    {/* <ReadMore
                    className={css.mobileDescription}
                    text={description !== null ? description : publicData?.bio}
                    maxLength={120}
                  /> */}
                  </div>
                  <SectionTextMaybe
                    className={css.desktopDescription}
                    text={description}
                    showAsIngress
                  />
                </div>
              ) : null}

              <div className={css.accordionList}>
                {categoryLevel1 ? (
                  <div className={css.accordionSections}>
                    <div className={css.headingName} onClick={() => toggleVisibility("category")}>
                      <FormattedMessage id="ListingPage.category" />
                      <span className={css.arrow}>
                        {categoryVisible.includes("category") ? (
                          <BrandingIconCard type="uparrow" />
                        ) : (
                          <BrandingIconCard type="downarrow" />
                        )}
                      </span>
                    </div>
                    {categoryVisible.includes("category") ? (
                      <div className={css.categoryLevelList}>
                        {categoryLevel1 ? (
                          <div className={css.categoryLevelName}>
                            {intl.formatMessage(listingType === DEAL_LISTING_TYPE ? { id: `dealCategory.${categoryLevel1}` } : { id: `category.${categoryLevel1Object?.id}` })}
                          </div>
                        ) : null}
                        {categoryLevel2 ? (
                          <div className={css.categoryLevelName}>
                            {intl.formatMessage(listingType === DEAL_LISTING_TYPE ? { id: `dealCategory.${categoryLevel2}` } : { id: `category.${categoryLevel2Object?.id}` })}
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                ) : null}

                {listingPrice ? (
                  <div className={css.accordionSections}>
                    <div className={css.headingName} onClick={() => toggleVisibility("price")}>
                      <FormattedMessage id="ListingPage.itemPrice" />
                      <span className={css.arrow}>
                        {categoryVisible.includes("price") ? (
                          <BrandingIconCard type="uparrow" />
                        ) : (
                          <BrandingIconCard type="downarrow" />
                        )}
                      </span>
                    </div>
                    {categoryVisible.includes("price") ? (
                      <div className={classNames(css.priceContent, dealOriginalPrice && css.dealDiscount)}>
                        {/* add a red lineStroke to this price if dealOriginalPrice exists */}
                        {dealOriginalPrice ?
                          <PriceMaybe
                            price={dealOriginalPrice}
                            publicData={publicData}
                            validListingTypes={config.listing.listingTypes}
                            intl={intl}
                            marketplaceCurrency={config.currency}
                          />
                          : null}

                        <PriceMaybe
                          price={listingPrice}
                          publicData={publicData}
                          validListingTypes={config.listing.listingTypes}
                          intl={intl}
                          marketplaceCurrency={config.currency}
                        />
                      </div>

                    ) : null}
                  </div>
                ) : null}

                {otherInfo ? (
                  <div className={css.accordionSections}>
                    <div className={css.headingName} onClick={() => toggleVisibility("otherInfo")}>
                      <FormattedMessage id="ListingPage.otherInformation" />
                      <span className={css.arrow}>
                        {categoryVisible.includes("otherInfo") ? (
                          <BrandingIconCard type="uparrow" />
                        ) : (
                          <BrandingIconCard type="downarrow" />
                        )}
                      </span>
                    </div>
                    {categoryVisible.includes("otherInfo") ? (
                      <CustomListingFields
                        publicData={publicData}
                        metadata={metadata}
                        listingFieldConfigs={listingConfig.listingFields}
                        categoryConfiguration={config.categoryConfiguration}
                        intl={intl}
                      />
                    ) : null}
                  </div>
                ) : null}
              </div>
              {listingType === DEAL_LISTING_TYPE ?
                <Heading as="h4" >
                  <FormattedMessage id="ListingPage.dealItemsHeading" />
                </Heading>
                : null}
              <div className={css.dealsItemWrapper}>
                {listingType === DEAL_LISTING_TYPE ?
                  dealItemsListings?.map((dealItem, index) => {
                    const { listingType } = dealItem?.attributes?.publicData;
                    const isBooked = dealServicesData.find(item => item?.id === dealItem?.id?.uuid);

                    return (
                      <div>
                        <DealItemsMaybe
                          dealItem={dealItem}
                          setIsBookingModalOpen={() => setIsBookingModalOpen(`ListingPage.DealItem${index}`)}
                          isBooked={isBooked}
                          onFetchDealItemMonthlyTimeSlots={onFetchDealItemMonthlyTimeSlots}
                        />
                        {listingType === SERVICE_LISTING_TYPE ?
                          <Modal
                            id={`ListingPage.DealItem${index}`}
                            isOpen={isBookingModalOpen === `ListingPage.DealItem${index}`} //TODO : Modify isOpen condition to only open 1 modal at a time
                            onClose={() => {
                              setIsBookingModalOpen(false);
                            }}
                            onManageDisableScrolling={onManageDisableScrolling}
                            className={css.dealItemModal}
                          >
                            <OrderPanel
                              isDealItem={isDeal}
                              isDealStarted={isDealStarted}
                              isDealValid={isDealValid}
                              setDealServicesData={setDealServicesData}
                              setIsBookingModalOpen={setIsBookingModalOpen}
                              className={css.productOrderPanel}
                              listing={dealItem}
                              isOwnListing={isOwnListing}
                              onFetchStaffListings={onFetchStaffListings}
                              staffListings={staffListings}
                              onSubmit={handleOrderSubmit}
                              fetchStaffListingsError={fetchStaffListingsError}
                              fetchStaffListingsInProgress={fetchStaffListingsInProgress}
                              cartProps={cartProps} // Add cartProps to OrderPanel
                              authorLink={
                                <NamedLink
                                  className={css.authorNameLink}
                                  name="ListingPage"
                                  params={params}
                                  to={{ hash: '#author' }}
                                >
                                  {authorDisplayName}
                                </NamedLink>
                              }
                              title={
                                <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                              }
                              titleDesktop={
                                <H4 as="h1" className={css.orderPanelTitle}>
                                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                                </H4>
                              }
                              payoutDetailsWarning={payoutDetailsWarning}
                              author={ensuredAuthor}
                              onManageDisableScrolling={onManageDisableScrolling}
                              onContactUser={onContactUser}
                              monthlyTimeSlots={itemMonthlyTimeSlots}
                              onFetchTimeSlots={onFetchTimeSlots}
                              onFetchTransactionLineItems={onFetchTransactionLineItems}
                              lineItems={lineItems}
                              fetchLineItemsInProgress={fetchLineItemsInProgress}
                              fetchLineItemsError={fetchLineItemsError}
                              validListingTypes={config.listing.listingTypes}
                              marketplaceCurrency={config.currency}
                              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                              marketplaceName={config.marketplaceName}
                              config={config}
                              currentUser={currentUser}
                              isBookingLink={isBookingLink}
                              merchantDetails={merchantDetails}
                              callSetInitialValues={callSetInitialValues}
                              onInitializeCardPaymentData={onInitializeCardPaymentData}
                              routeConfiguration={routeConfiguration}
                            />
                          </Modal>
                          : null}
                      </div>
                    )
                  })
                  : null}
              </div>

              {/* Reviews Summary */}
              <ReviewSummary
                listingPublicData={isDeal ? dealItemPublicData : publicData}
                ratings={ratings}
                ratingsInProgress={fetchRatingsInProgress}
                ratingsError={fetchRatingsError}
              />
              {/* Reviews Cards */}
              <h4><FormattedMessage id="ReviewCards.titleListing" values={{ name: title }} /></h4>
              <ReviewCards
                onManageDisableScrolling={onManageDisableScrolling}
                reviews={reviews}
                reviewsInProgress={fetchReviewsInProgress}
                reviewsError={fetchReviewsError}
              />

              {!isBookingLink ? (
                <SectionAuthorMaybe
                  title={title}
                  listing={businessProfileListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={onContactUser}
                  isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                  onCloseInquiryModal={() => setInquiryModalOpen(false)}
                  sendInquiryError={sendInquiryError}
                  sendInquiryInProgress={sendInquiryInProgress}
                  onSubmitInquiry={onSubmitInquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                  organizedCategories={organizedCategories}
                />
              ) : null}

              {relatedItemsListings?.length > 0 ?
                <div>
                  <div className={css.sliderRow}>
                    <Heading as="h2" rootClassName={css.sec}>
                      {getOtherLabel(listingType)}
                    </Heading>
                    <div
                      className={css.viewAll}
                      onClick={() => {
                        history.push(
                          createResourceLocatorString(
                            'AlgoliaSearchPage',
                            routeConfiguration,
                            { listingType: listingType + "s" },
                            { "publicData.categoryLevel2": categoryLevel2 }
                          )
                        );
                      }}
                    >
                      <FormattedMessage id="ListingPage.viewAll" />
                    </div>
                  </div>
                  <div className={css.relatedListContainer}>
                    <Slider {...settings}>
                      {relatedItemsListings.map(item => {
                        const { listingType } = item?.attributes?.publicData || {};
                        return listingType === PRODUCT_LISTING_TYPE ? (
                          <ProductCard
                            listing={item}
                            currentUser={currentUser}
                            toggleFavorite={onToggleFavorite}
                          />
                        ) : listingType === SERVICE_LISTING_TYPE ? (
                          <ServiceCard
                            listing={item}
                            currentUser={currentUser}
                            toggleFavorite={onToggleFavorite}
                          />
                        ) : listingType === DEAL_LISTING_TYPE ? (
                          <DealCard
                            listing={item}
                            currentUser={currentUser}
                            toggleFavorite={onToggleFavorite}
                            updatedDealCategories={updatedDealCategories}
                          />
                        ) : null
                      })}
                    </Slider>
                  </div>
                </div>
                : null}
            </div>
            <div className={css.orderColumnForProductLayout}>
              {(isOwnListing || isStaff) ? null : (
                <OrderPanel
                  className={css.productOrderPanel}
                  isDealStarted={isDealStarted}
                  isDealValid={isDealValid}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  onFetchStaffListings={onFetchStaffListings}
                  staffListings={staffListings}
                  onSubmit={handleOrderSubmit}
                  fetchStaffListingsError={fetchStaffListingsError}
                  fetchStaffListingsInProgress={fetchStaffListingsInProgress}
                  cartProps={cartProps} // Add cartProps to OrderPanel
                  authorLink={
                    <NamedLink
                      className={css.authorNameLink}
                      name="ListingPage"
                      params={params}
                      to={{ hash: '#author' }}
                    >
                      {authorDisplayName}
                    </NamedLink>
                  }
                  title={
                    <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                  }
                  titleDesktop={
                    <H4 as="h1" className={css.orderPanelTitle}>
                      <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                    </H4>
                  }
                  payoutDetailsWarning={payoutDetailsWarning}
                  author={ensuredAuthor}
                  onManageDisableScrolling={onManageDisableScrolling}
                  onContactUser={onContactUser}
                  monthlyTimeSlots={monthlyTimeSlots}
                  onFetchTimeSlots={onFetchTimeSlots}
                  onFetchTransactionLineItems={onFetchTransactionLineItems}
                  lineItems={lineItems}
                  fetchLineItemsInProgress={fetchLineItemsInProgress}
                  fetchLineItemsError={fetchLineItemsError}
                  validListingTypes={config.listing.listingTypes}
                  marketplaceCurrency={config.currency}
                  dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
                  marketplaceName={config.marketplaceName}
                  config={config}
                  dealItemsListings={dealItemsListings}
                  dealServicesData={dealServicesData}
                  isBookingLink={isBookingLink}
                  merchantDetails={merchantDetails}
                  callSetInitialValues={callSetInitialValues}
                  onInitializeCardPaymentData={onInitializeCardPaymentData}
                  routeConfiguration={routeConfiguration}
                />
              )}
            </div>
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsInProgress,
    fetchReviewsError,
    ratings,
    fetchRatingsInProgress,
    fetchRatingsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    fetchStaffListingsError,
    fetchStaffListingsInProgress,
    currentPageStaffIds,
    businessProfileListing,
    currentPageDealItemIds,
    currentPageRelatedItemIds = [],
    itemMonthlyTimeSlots,
  } = state.ListingPage;

  const { currentUser } = state.user;

  const { categoriesSuccess } = state?.EditListingPage || {};

  const staffListings = getListingsById(state, currentPageStaffIds);
  const dealItemsListings = getListingsById(state, currentPageDealItemIds);
  const relatedItemsListings = getListingsById(state, currentPageRelatedItemIds);

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsInProgress,
    fetchReviewsError,
    ratings,
    fetchRatingsInProgress,
    fetchRatingsError,
    monthlyTimeSlots,
    itemMonthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    staffListings,
    fetchStaffListingsError,
    fetchStaffListingsInProgress,
    businessProfileListing,
    dealItemsListings,
    categories: categoriesSuccess,
    relatedItemsListings,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchStaffListings: params => dispatch(fetchStaffListings(params)),
  onToggleCart: (listingId, authorId, increment, extraParams) =>
    dispatch(toggleCart({ listingId, authorId, increment, extraParams })),
  onFetchDealItemMonthlyTimeSlots: (listing) =>
    dispatch(fetchDealItemMonthlyTimeSlots(listing)),
  onToggleFavorite: (listingId, type, user) => dispatch(toggleFavorite(listingId, type, user)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(connect(mapStateToProps, mapDispatchToProps))(EnhancedListingPage);

export default ListingPage;
